import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Testimonial } from 'src/app/models/testimonial';
import { StoreService } from 'src/app/services/store.service';
import { DynamicScriptLoader } from "../../utils/dynamic-load-script";
let Typed = require("../../../assets/vendor/typed/typed.min.js");

declare var $:any;

@Component({
  selector: 'app-portafolio',
  templateUrl: './portafolio.component.html',
  styleUrls: ['./portafolio.component.scss']
})

export class PortafolioComponent implements OnInit {
  
  private dynamicScriptLoader = new DynamicScriptLoader();

  list: Testimonial[]=[] ;

  constructor(private StoreService: StoreService) { }

  ngOnInit() {
    let self = this;
    this.dynamicScriptLoader.loadScripts(
      [
        {
          name: "typed.js",
          src: "assets/vendor/typed/typed.min.js",
          element: "script"
        },
        {
          name: "waypoints.js",
          src: "assets/vendor/waypoints/jquery.waypoints.min.js",
          element: "script"
        },
        {
          name:"counterup.js",
          src:"assets/vendor/counterup/counterup.min.js",
          element:"script"
        },
        {
          name:"aos.js",
          src:"assets/vendor/aos/aos.js",
          element:"script"
        },
        {
          name:"isotope.js",
          src:"assets/vendor/isotope-layout/isotope.pkgd.min.js",
          element:"script"
        },
        {
          name:"venobox.js",
          src:"assets/vendor/venobox/venobox.min.js",
          element:"script"
        },
        {
          name:"easing.js",
          src:"assets/vendor/jquery.easing/jquery.easing.min.js",
          element:"script"
        },
        {
          name:"bootstrap.js",
          src:"assets/vendor/bootstrap/js/bootstrap.bundle.min.js",
          element:"script"
        },
        {
          name:"carousel.js",
          src:"assets/vendor/owl.carousel/owl.carousel.min.js",
          element:"script"
        },
        
        {
          name:"main.js",
          src:"assets/js/main.js",
          element:"script"
        },
        
      ],
      () => setTimeout(() => self.loadInitScripts(), 50)
    );  
    // this.getTestimonials();
  }

  loadInitScripts(){
    $(document).ready(function(){
      // var typed_strings = $(".typed").data('typed-items');
      // typed_strings = typed_strings.split(','),    
      // new Typed('.typed', {
      //   strings: typed_strings,
      //   loop: true,
      //   typeSpeed: 100,
      //   backSpeed: 50,
      //   backDelay: 2000
      // });
      $('[data-toggle="counter-up"]').counterUp({
        delay: 10,
        time: 2000
      });
       $(".testimonials-carousel").owlCarousel({
        autoplay: true,
        dots: true,
        loop: true,
        responsive: {
          0: {
            items: 1
          },
          768: {
            items: 2
          },
          900: {
            items: 3
          }
        }
      });
     })
  }

  getTestimonials(){
    this.StoreService.list().subscribe(data=>{
      this.list = data;      
    },error =>{
      console.log(error);
      
    });
  }

}
