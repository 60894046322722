<header id="header">
    <div class="d-flex flex-column">

      <div class="profile">
        <img src="assets/img/profile-img.jpg" alt="" class="img-fluid rounded-circle">
        <h1 class="text-light"><a href="javascript:void(0)">Miguel Revilla</a></h1>
        <div class="social-links mt-3 text-center">
          <a href="https://twitter.com/MiguelRevilla2" target="_blank" class="twitter"><i class="bx bxl-twitter"></i></a>
          <a href="https://www.facebook.com/miguelrevillapg" target="_blank" class="facebook"><i class="bx bxl-facebook"></i></a>
          <a href="https://www.instagram.com/miguelrevillapg/?hl=es-la" target="_blank" class="instagram"><i class="bx bxl-instagram"></i></a>
          <!-- <a href="javascript:void(0)" class="google-plus"><i class="bx bxl-skype"></i></a> -->
          <a href="https://www.linkedin.com/in/miguel-revilla-pejerrey-9b106a1b6/" target="_blank" class="linkedin"><i class="bx bxl-linkedin"></i></a> 
          <a href="https://api.whatsapp.com/send?phone=51904337329" target="_blank" class="wsp"><i class="fab fa-whatsapp"></i></a>
          
        </div>
      </div>

      <nav class="nav-menu">
        <ul>
          <li class="active"><a href="#hero"><i class="bx bx-home"></i> <span>Incio</span></a></li>
          <li><a href="#about"><i class="bx bx-user"></i> <span>Sobre mi</span></a></li>
          <li><a href="#skills"><i class="fas fa-laptop-code"></i> <span>Conocimientos</span></a></li>
          <li><a href="#resume"><i class="bx bx-file-blank"></i> <span>Experiencia</span></a></li>
          <!-- <li><a routerLink="/add-testimonials"><i class="bx bx-book-content"></i> Portafolio</a></li> -->
          <li><a href="#services"><i class="bx bx-server"></i> Servicios</a></li>
          <!-- <li><a href="#contact"><i class="bx bx-envelope"></i> Contáctame</a></li> -->
          <!-- <li><a routerLink="/add-testimonials"><i class="fa fa-plus"></i> Agrega un comentario</a></li> -->

        </ul>
      </nav><!-- .nav-menu -->
      <button type="button" class="mobile-nav-toggle d-xl-none"><i class="icofont-navigation-menu"></i></button>
    </div>
  </header>