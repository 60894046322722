<!DOCTYPE html>
<html lang="es">

<body>
    <div  class="container" style="padding-top: 100px;">
        <div class="col-lg-8 offset-lg-2" >
            <div class="card">
                <h5 class="card-header text-center"><strong>Agrega un testimonio</strong></h5>
                <div class="card-body">
                    <form [formGroup]="testimonialsForm" (ngSubmit)="storeTestimonials()">
                        <div class="mb-3">
                            <input type="text" formControlName="name" class="form-control" placeholder="Nombre completo">
                            <div class="text-danger" *ngIf="testimonialsForm.controls.name.errors != null && testimonialsForm.get('name')?.touched">
                                <span>Este campo es <strong>oblitario</strong></span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <input type="text" formControlName="stall" class="form-control" placeholder="Puesto que ejerce">
                            <div class="text-danger" *ngIf="testimonialsForm.controls.stall.errors != null && testimonialsForm.get('stall')?.touched">
                                <span>Este campo es <strong>oblitario</strong></span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <textarea cols="3" formControlName="description" rows="4" class="form-control" placeholder="Comentario"></textarea>
                            <div class="text-danger" *ngIf="testimonialsForm.controls.description.errors != null && testimonialsForm.get('description')?.touched">
                                <span>Este campo es <strong>oblitario</strong></span>
                            </div>
                        </div>
                        <div class="mb-3">
                            <input type="file" class="form-control">
                        </div>
                        <div class="mb-3 captcha">
                            <ngx-recaptcha2 #captchaElem
                                [siteKey]="siteKey"
                                formControlName="recaptcha"
                            >
                            </ngx-recaptcha2>
                        </div>
                        <div style="text-align: right;" >
                            <a routerLink="/" type="submit" class="btn btn-success" style="margin-right: 10px;"><i class="fas fa-close"></i> Atrás</a>
                            <button type="submit" class="btn btn-primary" [disabled]="testimonialsForm.invalid"><i class="fas fa-save"></i> Registrar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</body>
</html>


