import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Testimonial } from 'src/app/models/testimonial';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-addtestimonials',
  templateUrl: './addtestimonials.component.html',
  styleUrls: ['./addtestimonials.component.scss']
})
export class AddtestimonialsComponent implements OnInit {

  testimonialsForm: FormGroup
  
  constructor(
      private fb: FormBuilder, 
      private toastr: ToastrService,
      private StoreService: StoreService
  ) {
    this.testimonialsForm = this.fb.group({
      name: ['',Validators.required],
      stall: ['',Validators.required],
      description: ['',Validators.required],
      recaptcha: ['',Validators.required],
    });
   }

  ngOnInit(): void { 
    this.testimonialsForm = this.fb.group({
      name: ['',Validators.required],
      stall: ['',Validators.required],
      description: ['',Validators.required],
      recaptcha: ['',Validators.required],
    });
   }

   siteKey: string = "6Lcy7cEbAAAAAN-PF2uV_mMSXMeS15h8BBAdOrxm";

  storeTestimonials(){    
    const TESTIMONIAL: Testimonial = {
      name:  this.testimonialsForm.get('name')?.value,
      stall:  this.testimonialsForm.get('stall')?.value,
      description:  this.testimonialsForm.get('description')?.value,
      photo:""
    }
    this.StoreService.store(TESTIMONIAL).subscribe(data =>{
      this.toastr.success("Registrado correctamente!","TESTIMONIO");
      this.testimonialsForm.reset();
    },error =>{
      console.log(error);      
      this.toastr.error("ERROR","TESTIMONIO");
    });
    
  }

}
