import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
/** components */
import { PortafolioComponent } from './pages/portafolio/portafolio.component';
import { AddtestimonialsComponent } from './pages/addtestimonials/addtestimonials.component';

const routes: Routes = [
  {path:'',component: PortafolioComponent},
  {path:'add-testimonials', component: AddtestimonialsComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
