<app-header></app-header>
<!-- ======= Hero Section ======= -->
<section
  id="hero"
  class="d-flex flex-column justify-content-center align-items-center"
>
  <div class="hero-container" data-aos="fade-right">
    <h1>Miguel Revilla</h1>
    <p>
      I'm
      <span
        class="typed"
        data-typed-items="Fullstack, Back-Developer,Front-Developer, Freelancer"
      ></span>
    </p>
  </div>
</section>
<!-- End Hero -->

<main id="main">
  <!-- ======= About Section ======= -->
  <section id="about" class="about" style="padding-left: 50px">
    <div class="container">
      <div class="section-title">
        <h2>Sobre mi</h2>
        <p style="text-align: justify">
          Titulado en Ingeniería de Sistemas de la Universidad Nacional Pedro
          Ruiz Gallo - Lambayeque, con un "MASTER EN FULL STACK WEB DEVELOPMENT"
          de Universitat Politècnica de Catalunya (UPC)
          <strong>
            experto en Desarrollo de aplicaciones web, aplicaciones de
            escritorio</strong
          >, con sólidos conocimientos en la metodología ágil
          <strong
            >Extreme Programming o metodología XP y SCRUM(todos los
            roles)</strong
          >
          que permite llevar control de versiones y documentación organizada y
          clara.
        </p>
      </div>

      <div class="row">
        <div class="col-lg-4" data-aos="fade-right">
          <img src="assets/img/profile-img.jpg" class="img-fluid" alt="" />
        </div>
        <div class="col-lg-8 pt-4 pt-lg-0 content" data-aos="fade-left">
          <h3>Fullstack / Freelancer &amp; Web Developer, Desktop Developer</h3>
          <!-- <p class="font-italic">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
              magna aliqua.
            </p> -->
          <div class="row">
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Fec. Nacimiento:</strong> 21 de Julio 1993
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Website:</strong> www.devmiguelrevilla.com
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Celular:</strong> +51 904 337 329
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Ciudad:</strong> Chiclayo, PER
                </li>
              </ul>
            </div>
            <div class="col-lg-6">
              <ul>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Edad:</strong> 30
                </li>
                <!-- <li><i class="icofont-rounded-right"></i> <strong>:</strong> Master</li> -->
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Correo:</strong> mrp110793@gmail.com
                </li>
                <li>
                  <i class="icofont-rounded-right"></i>
                  <strong>Freelance:</strong> Disponible
                </li>
              </ul>
            </div>
          </div>
          <!-- <p>
              Officiis eligendi itaque labore et dolorum mollitia officiis optio vero. Quisquam sunt adipisci omnis et ut. Nulla accusantium dolor incidunt officia tempore. Et eius omnis.
              Cupiditate ut dicta maxime officiis quidem quia. Sed et consectetur qui quia repellendus itaque neque. Aliquid amet quidem ut quaerat cupiditate. Ab et eum qui repellendus omnis culpa magni laudantium dolores.
            </p> -->
        </div>
      </div>
    </div>
  </section>
  <!-- End About Section -->

  <!-- ======= Facts Section ======= -->
  <section id="facts" class="facts" style="padding-left: 50px">
    <div class="container">
      <div class="section-title">
        <h2>Hechos</h2>
        <p></p>
      </div>

      <div class="row no-gutters">
        <div
          class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
          data-aos="fade-up"
        >
          <div class="count-box">
            <i class="fas fa-calendar-alt"></i>
            <span data-toggle="counter-up">6</span>
            <p><strong>Años de experiencia</strong></p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="count-box">
            <i class="fas fa-user-plus"></i>
            <span data-toggle="counter-up">75</span>
            <p><strong>Proyectos/participaciones</strong></p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="count-box">
            <i class="icofont-live-support"></i>
            <span data-toggle="counter-up">57</span>
            <p><strong>Clientes</strong></p>
          </div>
        </div>

        <div
          class="col-lg-3 col-md-6 d-md-flex align-items-md-stretch"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="count-box">
            <i class="icofont-users-alt-5"></i>
            <span data-toggle="counter-up">5</span>
            <p><strong>Colaboradores</strong></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Facts Section -->

  <!-- ======= Skills Section ======= -->
  <section id="skills" class="skills section-bg" style="padding-left: 50px">
    <div class="container">
      <div class="section-title">
        <h2>Tecnologias</h2>        
      </div>
      <div class="row skills-content">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="text-align: center;">
          <!-- <div style="text-align: center; color: #3023c5;"data-aos="fade-up" ><h3>BACKEND</h3></div> -->
          <div class="row d-flex justify-content-center">
            <div class="col-lg-4 col-md-6 animated fadeInLeft" data-aos="fade-up">
              <div class="count-box"><h1 class="title" style="color: #149ddd;"><i class="fab fa-php"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">LARAVEL</a>
              </h4>
            </div>
            <div class="col-lg-4 col-md-6 animated fadeInLeft" data-aos="fade-up">
              <div class="count-box"><h1 class="title" style="color: #149ddd;"><i class="fab fa-node"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">EXPRESS</a>
              </h4>
            </div>
            <div class="col-lg-4 col-md-6 animated fadeInLeft text-center" data-aos="fade-up">
              <div class="count-box"><h1 class="title" style="color: #149ddd;"><i class="fab fa-java"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">SPRING</a>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12" style="text-align: center">
          <!-- <div data-aos="fade-up" style="text-align: center;color: #3023c5"><h3>FRONTEND</h3></div> -->
          <div class="row d-flex justify-content-center">
            <!-- <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up"></div> -->
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 class="title" style="color: #149ddd;"> <i class="fab fa-vuejs"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">VUEJS</a>
              </h4>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div><h1 class="title" style="color: #149ddd;"> <i class="fab fa-angular"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">ANGULAR</a>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center">
          <div style="text-align: center"><h1>&nbsp;</h1></div>
          <div class="row d-flex justify-content-center">
            <!-- <div class="col-lg-2 col-md-2 animated fadeInLeft" data-aos="fade-up"></div> -->
            <div class="col-lg-2 col-md-2 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fab fa-html5"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">HTML</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fab fa-css3"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">CSS</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fab fa-sass"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">SASS</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fab fa-js"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">JS</a>
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12" style="text-align: center">
          <div style="text-align: center"><h1>&nbsp;</h1></div>
          <div class="row d-flex justify-content-center">
            <!-- <div class="col-lg-1 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up"></div> -->
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fas fa-database"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">MONGODB</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="devicon-mysql-plain-wordmark"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">MYSQL</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="devicon-postgresql-plain-wordmark"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">POSTGRES</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="devicon-microsoftsqlserver-plain-wordmark"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">SERVER</a>
              </h4>
            </div>
            <div class="col-lg-2 col-md-2 col-sm-12 col-xs-12 animated fadeInLeft" data-aos="fade-up">
              <div ><h1 style="color: #149ddd;"><i class="fas fa-database"></i></h1></div>
              <h4 style="margin-top: 10px" class="title">
                <a href="javascript:void(0)">ORACLE</a>
              </h4>
            </div>
          </div>
        </div>
        <!-- 
          <div class="col-lg-6" data-aos="fade-up">

            <div class="progress">
              <span class="skill">Html/Css <i class="val">90%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="90"></div>
              </div>
            </div>

            <div class="progress">
              <span class="skill">Base de datos <i class="val">90%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div class="progress">
              <span class="skill">JavaScript <i class="val">95%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

          </div>

          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">

            <div class="progress">
              <span class="skill">Php/Laravel <i class="val">95%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="95" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div class="progress">
              <span class="skill">Vuejs <i class="val">90%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            <div class="progress">
              <span class="skill">Flutter <i class="val">55%</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" aria-valuenow="55" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>
          </div> -->
      </div>
    </div>
  </section>
  <!-- End Skills Section -->

  <!-- ======= Resume Section ======= -->
  <section id="resume" class="resume" style="padding-left: 50px">
    <div class="container">
      <div class="section-title">
        <h2>Experiencia</h2>
        <!-- <p style="text-align: justify;">
            Conocimiento de lenguajes de programación como lo son php y java, manejo de laravel Passport apirest en el Backend y el framework Vuejs en el Frontend.
            Conocimientos sólidos en el desarrollo de sockets usando node.js para chat y notificaciones en tiempo real.
            Conocimientos en el manejo de repositorios github y gitlab.
            Conocimientos en administración de base de datos como los son Mysql,Postgres y SQLServer.
            Experiencia en el manejo de hostings y dominios, configuración y manipulación de aplicaciones que se requieran subir a la nube.
            Con más de <strong> 05 años de experiencia profesional</strong>, laborando en empresas privadas en el rubro de desarrollo y soporte de sistemas.
         </p> -->
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-aos="fade-up">
          <h3 class="resume-title">Experiencia profesional</h3>
          <div class="resume-item">
            <h4>MDP CONSULTING</h4>
            <h5>01/06/2023 – 31/08/2023</h5>
            <p>
              <em> Sector: <strong>Tecnologias</strong></em>
            </p>
            <p>
              Analista programador backend microservicios java - cliente sunat
            </p>
          </div>
          <div class="resume-item">
            <h4>CANVIA</h4>
            <h5>27/12/2021 – 28/02/2023</h5>
            <p>
              <em> Sector: <strong>Tecnologias</strong></em>
            </p>
            <p>Analista tecnico microservicios java - cliente sunat</p>
          </div>
          <div class="resume-item">
            <h4>CLINICA DEL PACIFICO SAC</h4>
            <h5>01/06/2021 – 30/09/2021</h5>
            <p>
              <em> Sector: <strong>Salud</strong></em>
            </p>
            <p>Desarrollador FullStack</p>
          </div>
          <div class="resume-item">
            <h4>SERVISTOS EIRL</h4>
            <h5>09/03/2021 – 31/05/2021</h5>
            <p>
              <em> Sector: <strong>Marketing</strong></em>
            </p>
            <p>Encargado del area del sistemas</p>
          </div>
          <div class="resume-item">
            <h4>BM CLINICA</h4>
            <h5>01/08/2020 – 02/28/2021</h5>
            <p>
              <em> Sector: <strong>Salud</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>LA COCHERA</h4>
            <h5>01/11/2019 – 31/12/2019</h5>
            <p>
              <em> Sector: <strong>Carwash</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>POLLERIA “DON RODRIGO”</h4>
            <h5>01/09/2019 – 31/10/2019</h5>
            <p>
              <em> Sector: <strong>Restaurant</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>SUPER EXPRESS</h4>
            <h5>01/07/2019 – 31/08/2019</h5>
            <p>
              <em> Sector: <strong>Supermercado</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>SUPER SAC</h4>
            <h5>02/07/2018 – 06/10/2019</h5>
            <p>
              <em> Sector: <strong>Supermercado</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>ZIZEHOST SAC</h4>
            <h5>01/11/2017 – 31/12/2017</h5>
            <p>
              <em> Sector: <strong>Tecnologías de información</strong></em>
            </p>
            <p>Analista programador</p>
          </div>
          <div class="resume-item">
            <h4>ULTRATACTICA</h4>
            <h5>01/07/2017 – 31/10/2017</h5>
            <p>
              <em> Sector: <strong>Publicidad/Marketing</strong></em>
            </p>
            <p>Analista Desarrollador</p>
          </div>
          <div class="resume-item">
            <h4>INTICAP</h4>
            <h5>01/01/2017 – 30/06/2017</h5>
            <p>
              <em> Sector: <strong>Tecnologías de información</strong></em>
            </p>
            <p>Analista Desarrollador</p>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-aos="fade-up" data-aos-delay="100">
          <h3 class="resume-title">Experiencia Freelancer/Fullstack</h3>
          <div class="resume-item">
            <h4>
              SISTEMA PARA EL CONTROL DE INFORMACIÓN Y PROCESOS PARA LA EMPRESA “PERFILESCOFEE”
            </h4>
            <p><em>JAEN</em></p>
          </div>
          <div class="resume-item">
            <h4>
              SISTEMA PARA EL CONTROL DE VENTAS A CREDITO PARA LA EMPRESA “BAZAR POLICIAL PIZARRITO”
            </h4>
            <p><em>LIMA</em></p>
          </div>
          <div class="resume-item">
            <h4>
              SISTEMA PARA EL CONTROL INTERNO PARA LA INMOBILIARIA LAREDO SAC”
            </h4>
            <p><em>LAMBAYEQUE</em></p>
          </div>
          <div class="resume-item">
            <h4>
              SISTEMA PARA EL CONTROL INTERNO PARA LA INMOBILIARIA “EXPANDE SAC”
            </h4>
            <p><em>LAMBAYEQUE</em></p>
          </div>
          <div class="resume-item">
            <h4>
              PÁGINA WEB INFORMATIVA Y ADMINISTRABLE PARA EL INSTITUTO “SANTOS
              VILLALOBOS HUAMÁN” DE CAJAMARCA
            </h4>
            <p><em>CAJAMARCA</em></p>
          </div>
          <div class="resume-item">
            <h4>
              PÁGINA WEB INFORMATIVA Y ADMINISTRABLE PARA LA MUNICIPALIDAD DE
              PACORA
            </h4>
            <p><em>LAMBAYEQUE </em></p>
          </div>
          <div class="resume-item">
            <h4>SISTEMA PARA CONTROL DE INVENTARIO Y COTIZACIONES</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>SISTEMA PARA CLINICA (CLINICA ALFONSO UGARTE - CHICLAYO)</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>
              CAMPUS VIRTUAL Y MATRICULAS ONLINE (COLEGIOS EXCELENCIA SEDE
              JAYANCA Y TÚCUME)
            </h4>
            <p><em>TÚCUME </em></p>
          </div>
          <div class="resume-item">
            <h4>SISTEMA DE PRODUCCIÓN Y VENTAS (VELMON SAC)</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>ERP PARA RESTAURANTE (RECREO CAMPESTRE PIRKAS - JAYANCA)</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>VENTA DE TICKETS ONLINE PARA SORTEOS (ESMON SAC)</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>ECOMMERCE PARA RUTAS DEL INCA</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>
              SISTEMA DE CONTROL INTERNO PARA UN MINIMARKET (MINIMARKET
              CONMIFAMILIA)
            </h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>
              TESIS PARA LA OBTENCIÓN DE GRADO DE INGENIERO DE SISTEMAS PARA LA
              UNIVERSIDAD NACIONAL PEDRO RUIZ GALLO DENOMINADO “RED SOCIAL PARA
              LA PROGRAMACIÓN DE ENCUENTROS FUTBOLISTICOS EN LA CIUDAD DE
              CHICLAYO"
            </h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>ECOMMERCE PARA SUPERMERCADOS EN SUPER</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>SISTEMA DE DELIVERY PARA SUPERMERCADOR EL SUPER</h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>
              SISTEMA PARA GESTION DE RESTAURANTES (RESTO PEÑA CON MI FAMILIA)
            </h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>SISTEMA INVENTARIO PARA HOSPITAL DE APOYO BAGUA</h4>
            <p><em>Bagua </em></p>
          </div>
          <div class="resume-item">
            <h4>
              CENECP-INTRANET (CENTRO NACIONAL DE ESTUDIOS CRIMINALOGICOS Y
              PENITENCIARIOS)
            </h4>
            <p><em>Lambayeque </em></p>
          </div>
          <div class="resume-item">
            <h4>ICAL-INTRANET (COLEGIO DE ABOGADOS DE LAMBAYEQUE)</h4>
            <p><em>Lambayeque </em></p>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Resume Section -->
  <!-- ======= Certificate Section ======= -->
  <!-- <section id="skills" class="skills section-bg" style="padding-left: 50px">
    <div class="container">
      <div class="section-title">
        <h2>Certificaciones Agiles</h2>
        <p></p>
      </div>
      <div class="row skills-content">
        <div class="col-lg-6" style="text-align: center">
          <div data-iframe-width="150" data-iframe-height="270" data-share-badge-id="3675fefa-4f21-4cfa-b246-4b35b000b0d3" data-share-badge-host="https://www.credly.com">
          </div><script type="text/javascript" async src="//cdn.credly.com/assets/utilities/embed.js"></script>
        </div>
      </div>
    </div>
  </section> -->
  <!-- ======= Certificate Section ======= -->

  <!-- ======= Portfolio Section ======= -->
  <!--
    <section id="portfolio" class="portfolio section-bg" >
      <div class="container">

        <div class="section-title">
          <h2>Portafolio</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row" data-aos="fade-up">
          <div class="col-lg-12 d-flex justify-content-center">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div>

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="100">

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-1.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-1.jpg" data-gall="portfolioGallery" class="venobox" title="App 1"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-2.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-3.jpg" data-gall="portfolioGallery" class="venobox" title="App 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-4.jpg" data-gall="portfolioGallery" class="venobox" title="Card 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-5.jpg" data-gall="portfolioGallery" class="venobox" title="Web 2"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-app">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-6.jpg" data-gall="portfolioGallery" class="venobox" title="App 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-7.jpg" data-gall="portfolioGallery" class="venobox" title="Card 1"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-card">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-8.jpg" data-gall="portfolioGallery" class="venobox" title="Card 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 portfolio-item filter-web">
            <div class="portfolio-wrap">
              <img src="assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
              <div class="portfolio-links">
                <a href="assets/img/portfolio/portfolio-9.jpg" data-gall="portfolioGallery" class="venobox" title="Web 3"><i class="bx bx-plus"></i></a>
                <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
              </div>
            </div>
          </div>

        </div>

      </div>
    </section><!-- End Portfolio Section -->
  <!-- ======= Services Section ======= -->
  <section id="services" class="services skills section-bg">
    <div class="container">
      <div class="section-title">
        <h2>Servicios</h2>
        <!-- <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p> -->
      </div>

      <div class="row">
        <div class="col-lg-4 col-md-6 icon-box" data-aos="fade-up">
          <div class="icon"><i class="icofont-computer"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Desarrollo web a medida</a>
          </h4>
          <!-- <p class="description">Voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident</p> -->
        </div>
        <div
          class="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div class="icon"><i class="fas fa-mobile-alt"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Aplicaciones móviles</a>
          </h4>
          <!-- <p class="description">Minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat tarad limino ata</p> -->
        </div>
        <div
          class="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="icon"><i class="fas fa-server"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Hostings y dominios</a>
          </h4>
          <!-- <p class="description">Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur</p> -->
        </div>
        <div
          class="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="300"
        >
          <div class="icon"><i class="fas fa-file-invoice-dollar"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Facturación electrónica</a>
          </h4>
          <!-- <p class="description">Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p> -->
        </div>
        <div
          class="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          <div class="icon"><i class="fas fa-tablet-alt"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Consultas recniec y sunat</a>
          </h4>
          <!-- <p class="description">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque</p> -->
        </div>
        <div
          class="col-lg-4 col-md-6 icon-box"
          data-aos="fade-up"
          data-aos-delay="500"
        >
          <div class="icon"><i class="fas fa-cart-arrow-down"></i></div>
          <h4 style="margin-top: 10px" class="title">
            <a href="javascript:void(0)">Tiendas online</a>
          </h4>
          <!-- <p class="description">Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi</p> -->
        </div>
      </div>
    </div>
  </section>
  <!-- End Services Section -->

  <!-- ======= Testimonials Section ======= -->
  <!-- <section id="testimonials" class="testimonials section-bg">
      <div class="container">

        <div class="section-title">
          <h2>Comentarios</h2>
          <p></p>
        </div>

        <div class="owl-carousel testimonials-carousel">

          <div *ngFor="let item of list" class="testimonial-item" data-aos="fade-up" data-aos-delay="100">
            <p>
              <i class="bx bxs-quote-alt-left quote-icon-left"></i>
              {{item.description}}
              <i class="bx bxs-quote-alt-right quote-icon-right"></i>
            </p>
            <img src="{{item.photo}}" class="testimonial-img" alt="">
            <h3>{{item.name}}</h3>
            <h4>{{item.stall}}</h4>
          </div>
        </div>

      </div>
    </section> -->
  <!-- End Testimonials Section -->

  <!-- ======= Contact Section ======= -->
  <!-- <section id="contact" class="contact">
      <div class="container">

        <div class="section-title">
          <h2>Contact</h2>
          <p>Magnam dolores commodi suscipit. Necessitatibus eius consequatur ex aliquid fuga eum quidem. Sit sint consectetur velit. Quisquam quos quisquam cupiditate. Et nemo qui impedit suscipit alias ea. Quia fugiat sit in iste officiis commodi quidem hic quas.</p>
        </div>

        <div class="row" data-aos="fade-in">

          <div class="col-lg-5 d-flex align-items-stretch">
            <div class="info">
              <div class="address">
                <i class="icofont-google-map"></i>
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>

              <div class="email">
                <i class="icofont-envelope"></i>
                <h4>Email:</h4>
                <p>info@example.com</p>
              </div>

              <div class="phone">
                <i class="icofont-phone"></i>
                <h4>Call:</h4>
                <p>+1 5589 55488 55s</p>
              </div>

              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621" frameborder="0" style="border:0; width: 100%; height: 290px;" allowfullscreen></iframe>
            </div>

          </div>

          <div class="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="form-row">
                <div class="form-group col-md-6">
                  <label for="name">Your Name</label>
                  <input type="text" name="name" class="form-control" id="name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                  <div class="validate"></div>
                </div>
                <div class="form-group col-md-6">
                  <label for="name">Your Email</label>
                  <input type="email" class="form-control" name="email" id="email" data-rule="email" data-msg="Please enter a valid email" />
                  <div class="validate"></div>
                </div>
              </div>
              <div class="form-group">
                <label for="name">Subject</label>
                <input type="text" class="form-control" name="subject" id="subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
                <div class="validate"></div>
              </div>
              <div class="form-group">
                <label for="name">Message</label>
                <textarea class="form-control" name="message" rows="10" data-rule="required" data-msg="Please write something for us"></textarea>
                <div class="validate"></div>
              </div>
              <div class="mb-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
    </section> -->
  <!-- End Contact Section -->
</main>
<!-- End #main -->
<app-footer></app-footer>
